<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="dossiers"
      class="elevation-0 myTable"
      disable-pagination
      hide-default-footer
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucun dossier
      </template>
      <template v-slot:top>
        <v-toolbar flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">Dossiers </v-toolbar-title>
          <text-input
            prepend-inner-icon="mdi-magnify"
            @keyup="handleSearch"
            class="ml-5 mt-5"
            id="search"
            v-model="search"
            placeholder="Nom du dossier"
          />

          <v-switch
            v-model="statusSwitch"
            :label="switchLabel"
            color="#86bc26"
            hide-details
            inset
            style="margin-left: 20px;"
          ></v-switch>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{ on, attrs }">
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2"
                action_name="Exporter"
                color="primary"
                text
                @clicked="handleExportClicked"
                :loading="loadingExport"
              >
                <template v-slot:button-content>
                  <v-img
                    :src="require('@/assets/export.svg')"
                    alt="humberger"
                  />
                </template>
              </Button>
              <Button
                v-bind="attrs"
                v-on="on"
                class="mb-2 mr-2"
                action_name="Ajouter"
                color="primary"
                text
                @clicked="openAddDialog"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          v-model="editedItem.name"
                          label="Nom du dossier*"
                          id="name"
                          :rules="requiredRules"
                        />
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            :rules="requiredRules"
                            label="Client*"
                            id="client"
                            v-model="editedItem.client"
                            :items="clients"
                            item-text="name"
                            item-value="id"
                          />
                        </v-col>
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          v-model="editedItem.type"
                          label="Type de dossier*"
                          id="type"
                          :rules="requiredRules"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="status"
                          label="Statut*"
                          v-model="editedItem.status"
                          :items="dossier_status"
                          item-text="name"
                          item-value="id"
                          :rules="requiredRules"
                        />
                      </v-col>
                      <!-- <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input
                          v-model="editedItem.estimatedHours"
                          label="Nombre d'heures estimées"
                          id="estimatedHours"
                        />
                      </v-col> -->
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  v-if="!showEditButton"
                  :loading="loading"
                />
                <Button
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  :loading="loadingAdd"
                  @clicked="save(false)"
                  v-if="!showEditButton"
                />
                <Button
                  action_name="Modifier"
                  color="#11887E"
                  text
                  @clicked="update(false)"
                  v-if="showEditButton"
                  :loading="loadingEdit"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn
                  color="black"
                  dark
                  icon
                  right
                  @click="filterDialog = false"
                >
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="dossiers"
                        v-model="filteredItem.dossiers"
                        :items="activeDossiers"
                        label="Dossier"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="typesDossier"
                        v-model="filteredItem.typesDossier"
                        :items="typesDossier"
                        label="Type"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="department"
                        v-model="filteredItem.department"
                        :items="departments"
                        label="Département"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="client"
                        v-model="filteredItem.client"
                        :items="clients"
                        label="Client"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="teams"
                        v-model="filteredItem.teams"
                        :items="activeUsers"
                        label="Équipe"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pb-0 pt-0">
                      <label>Date de début</label>
                      <custom-date-picker v-model="filteredItem.startDate" />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pb-0 pt-0">
                      <label>Date de fin</label>
                      <custom-date-picker v-model="filteredItem.endDate" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <Button
                  action_name="Filtrer"
                  color="#86CC26"
                  @clicked="
                    pageNumber = 1;
                    getDossiers();
                    filterDialog = false;
                  "
                  style="width: 220px"
                  class="ml-5 mr-5 mb-5"
                />
                <span class="mb-5" style="color:#B2BBAB" @click="handleReset"
                  >Réinitialiser</span
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(dossiers, index) in filteredItem.dossiers">
                  <v-chip
                    class="ma-1"
                    :key="dossiers.name"
                    close
                    @click:close="handleChipDelete(index, 'dossiers')"
                    >Dossier: {{ dossiers.name }}</v-chip
                  >
                </template>
                <template
                  v-for="(typesDossier, index) in filteredItem.typesDossier"
                >
                  <v-chip
                    class="ma-1"
                    :key="typesDossier"
                    close
                    @click:close="handleChipDelete(index, 'typesDossier')"
                    >Type: {{ typesDossier }}</v-chip
                  >
                </template>
                <template
                  v-for="(department, index) in filteredItem.department"
                >
                  <v-chip
                    class="ma-1"
                    :key="department"
                    close
                    @click:close="handleChipDelete(index, 'department')"
                    >Département: {{ department }}</v-chip
                  >
                </template>
                <template v-for="(client, index) in filteredItem.client">
                  <v-chip
                    class="ma-1"
                    :key="client.name"
                    close
                    @click:close="handleChipDelete(index, 'client')"
                    >Client:{{ client.name }}</v-chip
                  >
                </template>
                <template v-for="(teams, index) in filteredItem.teams">
                  <v-chip
                    class="ma-1"
                    :key="teams.name"
                    close
                    @click:close="handleChipDelete(index, 'teams')"
                    >Équipe:{{ teams.name }}</v-chip
                  >
                </template>
                <template v-if="filteredItem.startDate && filteredItem.endDate">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleMultipleDateDelete"
                    >Entre le :{{ filteredItem.startDate }} et le :
                    {{ filteredItem.endDate }}</v-chip
                  >
                </template>
                <template
                  v-if="filteredItem.startDate && !filteredItem.endDate"
                >
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleExactChipDelete('startDate')"
                    >Aprés le :{{ filteredItem.startDate }}</v-chip
                  >
                </template>
                <template
                  v-if="filteredItem.endDate && !filteredItem.startDate"
                >
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleExactChipDelete('endDate')"
                    >Avant le :{{ filteredItem.endDate }}</v-chip
                  >
                </template>
              </div>
              <div>
                <v-chip class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined
                    >mdi-filter</v-icon
                  >
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <v-icon v-if="item.status === 0" class="outlined inactive-status"
          >mdi-close</v-icon
        >
        <v-icon v-if="item.status === 1" class="outlined active-status"
          >mdi-check</v-icon
        >
        {{ item.name }}
      </template>
      <template v-slot:item.affectation="{ item }">
        <div :key="item.agent">
          <template v-for="i in item.affectation">
            <Avatar
              user="false"
              :item="i.agent"
              :key="i.agent.id"
              @clicked="handleAvatarClick(i.agent.id)"
              style="margin-left:-10px"
            />
          </template>
        </div>
      </template>
      <template v-slot:item.departement="{ item }">
        <div :key="item.agent">
          {{ getDepartments(item.affectation) }}
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | moment("dddd DD/MM/YYYY") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="editItem(item)"
          class="ml-2 mt-1"
          style="min-width: 111px"
          >Modifier</v-btn
        >
        <v-btn
          @click="handleTableClick(item)"
          class="ml-2 mt-1"
          style="min-width: 111px"
          >Consulter</v-btn
        >
      </template>
    </v-data-table>
    <v-dialog v-model="agentDialog" max-width="418px">
      <v-card>
        <v-card-title class="justify-space-between">
          <span class="headline">Détails ressource</span>
          <v-btn color="black" dark icon right @click="close">
            <v-icon color="#B2BBAB">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pa-0" cols="12" md="12" sm="6">
                <strong>Nom et prénom :</strong>
                <span>{{ userData.name }}</span>
              </v-col>
              <v-col class="pa-0" cols="12" md="12" sm="6">
                <strong>Email :</strong>
                <span>{{ userData.email }}</span>
              </v-col>
              <v-col class="pa-0" cols="12" md="12" sm="6">
                <strong>Département :</strong>
                <span>{{ userData.department }}</span>
              </v-col>
              <v-col class="pa-0" cols="12" md="12" sm="6">
                <strong>Fonction :</strong>
                <span>{{ userData.function }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import departments from "@/assets/data/departments.json";
import functions from "@/assets/data/functions.json";
import { getUserById } from "@/services/users.api";
import {
  Button,
  TextInput,
  AutoComplete,
  Avatar,
  CustomDatePicker,
} from "@/components/base";
import {
  getAllDossiers,
  addDossier,
  updateDossier,
  searchDossier,
  exportAllProject,
} from "@/services/dossiers.api";
import { getClientsList } from "@/services/clients.api";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
import {
  getActiveDossiers,
  getTypesDossier,
} from "../../../services/dossiers.api";
import { getActiveUsers } from "../../../services/users.api";

export default {
  components: { Button, TextInput, AutoComplete, Avatar, CustomDatePicker },
  mixins: [infinityScrollMixin],
  data: () => ({
    filterDialog: false,
    total: 0,
    options: {},
    search: "",
    isValid: true,
    functions: functions,
    departments: departments,
    dossier_status: [
      { id: 1, name: "Actif" },
      { id: 0, name: "Inactif" },
    ],
    text: "",
    agentDialog: false,
    showEditButton: false,
    userData: [],
    loading: false,
    loadingAdd: false,
    loadingEdit: false,
    items: [],
    dialog: false,
    headers: [
      {
        text: "Dossier",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Département", value: "departement" },
      { text: "Client", value: "client.name" },
      { text: "Type", value: "type" },
      { text: "Equipe", value: "affectation" },
      { text: "Heures estimées", value: "totEstimatedHours" },
      { text: "Heures consommées", value: "totConsumedHours" },
      { text: "Date de création", value: "created_at" },
      // { text: "Durée", value: "duration" }
      { text: "Actions", value: "actions", sortable: false },
    ],
    dossiers: [],
    editedIndex: -1,
    editedItem: {},
    filteredItem: { status: { id: 1, name: "Actif" } },
    defaultItem: {},
    clients: [],
    timeout: null,
    pageNumber: 1,
    last_page: 0,
    department: "",
    loadingExport: false,
    activeDossiers: [],
    typesDossier: [],
    activeUsers: [],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau dossier" : "Modifier dossier";
    },
    switchLabel() {
      return this.statusSwitch ? "Actif" : "Inactif";
    },
    statusSwitch: {
      get() {
        return this.filteredItem.status.name === "Actif";
      },
      set(value) {
        this.filteredItem.status = value
          ? { id: 1, name: "Actif" }
          : { id: 0, name: "Inactif" };
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.filteredItem.order_by = this.options.sortBy[0];
        this.filteredItem.order_by_direction = this.options.sortDesc[0];
        this.dossiers = [];
        this.pageNumber = 1;
        getAllDossiers(this.pageNumber, this.filteredItem).then((response) => {
          this.dossiers = response.data.data;
          for (let i in response.data.data) {
            let ids = this.dossiers[i].affectation.map((o) => o.user_id);
            this.dossiers[i].affectation = this.dossiers[i].affectation.filter(
              ({ user_id }, index) => !ids.includes(user_id, index + 1)
            );
          }
          this.last_page = response.data.last_page;
          this.total = response.data.total;
        });
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    agentDialog(val) {
      val || this.close();
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1;
        getAllDossiers(this.pageNumber, this.filteredItem).then((response) => {
          let responseArray = response.data.data;
          for (let i in response.data.data) {
            let ids = this.dossiers[i].affectation.map((o) => o.user_id);
            this.dossiers[i].affectation = this.dossiers[i].affectation.filter(
              ({ user_id }, index) => !ids.includes(user_id, index + 1)
            );
          }
          this.dossiers = [...this.dossiers, ...responseArray];
          this.total = response.data.total;
        });
      }
    },
    "filteredItem.department": {
      handler: function(newDepartment, oldDepartment) {
        if (JSON.stringify(newDepartment) !== JSON.stringify(oldDepartment)) {
          this.department = newDepartment;
          this.getClients();
        }
      },
      deep: true,
    },
    "filteredItem.status": {
      handler() {
        if (this.search !== "") {
          this.handleSearch();
        } else {
          this.getDossiers();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    handleExactChipDelete(key) {
      this.filteredItem[key] = "";
      this.getDossiers();
    },
    handleChipDelete(index, key) {
      this.filteredItem[key].splice(index, 1);
      this.getDossiers();
    },
    handleReset() {
      this.filteredItem = {};
      this.getDossiers();
    },
    getClients() {
      getClientsList(this.department).then((response) => {
        this.clients = response.data;
      });
    },
    getDossiers() {
      getAllDossiers(this.pageNumber, this.filteredItem).then((response) => {
        this.dossiers = response.data.data;
        for (let i in response.data.data) {
          let ids = this.dossiers[i].affectation.map((o) => o.user_id);
          this.dossiers[i].affectation = this.dossiers[i].affectation.filter(
            ({ user_id }, index) => !ids.includes(user_id, index + 1)
          );
        }
        this.last_page = response.data.last_page;
        this.total = response.data.total;
      });
    },
    handleSearch() {
      if (this.search !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          searchDossier(this.search, this.filteredItem.status.id).then(
            (response) => {
              this.dossiers = response.data;
            }
          );
        }, 1000);
      } else {
        this.getDossiers();
      }
    },
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.dialog = true;
    },
    handleExportClicked() {
      this.loadingExport = true;
      exportAllProject(this.filteredItem)
        .then((response) => {
          this.loadingExport = false;

          const blob = new Blob([response.data], { type: "application/csv" });
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(blob);
          link.download = `liste_des_projets.csv`;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        })
        .catch((error) => {
          this.handleFail(error);
        });
    },
    handleAvatarClick(id) {
      getUserById(id).then((response) => {
        this.agentDialog = true;
        this.userData = response.data;
      });
    },
    handleTableClick(row) {
      this.$router.push({
        path: "/dashboard/dossier",
        query: { project: row.name },
      });
    },
    initialize() {
      getClientsList(this.department).then((response) => {
        this.clients = response.data;
      });
      getActiveDossiers().then((response) => {
        this.activeDossiers = response.data.dossiers;
      });
      getTypesDossier().then((response) => {
        this.typesDossier = response.data.typesDossier;
      });
      getActiveUsers().then((response) => {
        this.activeUsers = response.data.users;
      });
      this.getDossiers();
    },
    editItem(item) {
      this.showEditButton = true;
      this.editedIndex = this.dossiers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.client = this.editedItem.client.id;
      this.dialog = true;
    },
    close() {
      this.agentDialog = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.showEditButton = false;
      });
    },
    update() {
      this.loadingEdit = true;
      updateDossier(this.editedItem).then(() => {
        this.loadingEdit = false;
        this.showEditButton = false;
        this.getDossiers();
        this.close();
        this.$swal.fire({ title: "Dossier modifier avec succès" });
      });
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      dialogStat ? (this.loading = true) : (this.loadingAdd = true);
      addDossier(this.editedItem).then(() => {
        dialogStat ? (this.loading = false) : (this.loadingAdd = false);
        this.close();
        this.$refs.form.reset();
        this.dialog = dialogStat;
        this.$swal.fire({ title: "Dossier ajouté avec succès" });
        this.getDossiers();
      });
    },
    handleFail(error) {
      this.text = error;
      this.snackbar = true;
    },
    getDepartments(affectation) {
      const uniqueDepartments = [
        ...new Set(affectation.map((i) => i.agent.department)),
      ];
      return uniqueDepartments.join(", ");
    },
    handleMultipleDateDelete() {
      this.filteredItem["startDate"] = "";
      this.filteredItem["endDate"] = "";
      this.getDossiers();
    },
  },
};
</script>
<style scoped>
.myTable .datatable thead th.column.sortable i {
  display: none;
}

.v-icon.outlined {
  border: 1px solid currentColor;
  border-radius: 50%;
  padding: 3px;
}
</style>
